import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/gregoirethibaud/Desktop/Brusketa/Developpement/brusketa/node_modules/gatsby-theme-docz/src/base/Layout.js";
import BreadCrumbs from './Breadcrumbs';
import { Playground, Props } from 'docz';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "breadcrumbs"
    }}>{`Breadcrumbs`}</h1>
    <p>{`Breadcrumbs component render a navigation means of the path.`}</p>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={BreadCrumbs} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={1} __code={'<BreadCrumbs />'} __scope={{
      props,
      DefaultLayout,
      BreadCrumbs,
      Playground,
      Props,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <BreadCrumbs mdxType="BreadCrumbs" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      